#top{
	
	.header-logo{
		margin-right: auto !important;
	}
	select::-ms-expand {
		display: none;
	}
	.gca-post-grid{
		.entry-post{
			.entry-content-inner{
				padding-right: 40px;
			}
			.entry-title, .entry-excerpt{
				width: 100%;
			}
		}
	}
	.testimonial-row-static{
		position: relative;
		justify-content: flex-end;
		.flex_column{
			&:first-child{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 50% !important;
			}
		}
	}
	.homepage--special-buttons{
		.flex-column-inner{
			justify-content: center !important;
			.special-button{
				width: 240px !important;
				margin-right: 25px;
			}
		}
	}
	.footer--row-3{
		.av-flex-placeholder{
			width: 10%;
		}
	}
	.padded-box-w-img{
		&:before{
			content: '';
			position: absolute;
			height: 65%;
			width: 4px;
			left: 55%;
			background-color: rgba(white, 0.26);
		}
		.flex_column:last-child{
			top: 193px;
		}
	}
	.testimonial-section{
		.av-special-heading{
			top: 175px !important;
		}
		.flickity-page-dots{
			transform: translateY(800%);
		}
	}
	.ep-tab-slider{
		.ep-content-item{
			top: 8%;
			padding-left: 50px;
			height: 600px;
			.flickity-viewport{
				height: 600px;
			}
		}
	}
	.home--hero-row{
		.flex_column{
			display: flex;
		}
	}
	.facetwp-search-wrap{
		&:before{
			top: 3px !important;
		}
	}
	.ep-tab-slider-timeline-content{
		padding-bottom: 120px;
	}
	.quad9--box-image{
		img{
			width: 500px;
		}
	}
	.box-class-1-with-image{
		.avia-image-container-inner{
			width: 100%;
			display: flex;
			justify-content: center;
		}
		.av_textblock_section{
			height: 130px;
		}
	}
	.single-post-content-section{
		.av-share-box-list{
			li{
				display: block;
			}
		}
	}
}

.only-ie {
	display: flex!important;
	position: absolute;
    width: 100% !important;
    height: 100%;
    top: 0;
	left: 0;
	align-items: center;
}

.only-ie h3 {
	position: absolute;
    width: 100%;
    text-align: center;
	transform: translateY(-50%);
}

.only-ie h3 a {
	font-weight: 700 !important;
}

.only-ie h3 a:hover{
	color: #F75642 !important;
}